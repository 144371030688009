<template>
    <div
        class="condition-wrapper"
        v-if="isHasListData && dataList?.length > 0 && !isLoading"
    >
        <div class="pc" v-if="isShowTable">
            <div
                class="search-remark"
                v-for="(data, index) in dataList"
                :key="index"
            >
                <p class="title">
                    {{ data.title }}
                    <span
                        v-if="data.tooltip"
                        class="info-icon"
                        :tooltip="data.tooltip"
                        tooltip-touch
                    />
                    <span class="value">：{{ data?.value || '-' }}</span>
                </p>
            </div>
        </div>
        <div class="mobile">
            <div class="condition" @click="isShowCondition = !isShowCondition">
                查詢條件
                <div
                    class="toggle-menu-icon"
                    :class="{ active: isShowCondition }"
                />
            </div>
            <transition name="dropdown">
                <div v-if="isShowCondition">
                    <div v-for="(data, index) in dataList" :key="index">
                        <div class="search-remark">
                            <p class="title">
                                {{ data.title }}
                                <span
                                    v-if="data.tooltip"
                                    class="info-icon"
                                    :tooltip="data.tooltip"
                                    tooltip-touch
                                />
                            </p>
                            <p class="value">{{ data?.value || '-' }}</p>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchCondition',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        dataList: {
            type: Array,
            default: function () {
                return []
            }
        },
        isShowTable: {
            type: Boolean,
            default: false
        },
        isHasListData: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isShowCondition: true
        }
    }
}
</script>

<style lang="scss" scoped>
.condition-wrapper {
    .pc,
    .mobile {
        padding-bottom: 20px;
    }

    .pc {
        display: block;
        .search-remark {
            font-size: 14px;
        }
        @media screen and (max-width: 576px) {
            display: none;
        }
    }
    .mobile {
        display: none;
        @media screen and (max-width: 576px) {
            display: block;
        }
    }
}
.condition {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 16px;
    color: $sixth-black;
    cursor: pointer;
    .toggle-menu-icon {
        height: 10px;
        width: 10px;
        margin-left: 10px;
        transition: transform 0.3s;
        transform: rotate(0deg);
        &.active {
            transform: rotate(90deg);
        }
    }
}
.search-remark {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    p {
        margin: 0;
    }
    .title,
    .value {
        color: $sixth-black;
    }
}

.info-icon {
    width: 14px;
    height: 14px;
}
</style>
